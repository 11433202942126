#offer {
  margin: 0 5%;
}

.offer-description {
  line-height: 1.8;
}

.column > ul > li > a {
  color: #da2f10;
  text-decoration: none;
}

.column > b, .column > ul {
  color: #6C7476;
}

@media only screen and (min-width: 1000px) {
  #offer {
    height: 120vh;
  }
  .column {
    float: left;
    width: 30%
  }
}

@media only screen and (max-width: 999px) {
  p {
    text-align: justify;
  }

  li {
  margin-right: 12%;
  }
}