.slider {
  width: 100%;
  height: 500px;
  position: relative;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateX(-50%);
  transition: all 0.5s ease;
}

@media screen and (min-width: 600px) {
  .slide img {
    width: 50%;
    height: 50%;
  }
}

.slide img {
  height: 500px;
}

.current {
  opacity: 1;
  transform: translateX(0);
}

@keyframes slide-up {
  0% {
    visibility: visible;
    top: 23rem;
  }
  100% {
    visibility: visible;
    top: 17rem;
  }
}

.current .content {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.5s ease;
}