html {
  position: relative;
  height: 100%;padding:0; margin:0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;padding:0; margin:0; height:100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

footer {
  bottom: 0;
  padding: 1rem 2rem;
  width: 100%;
  height: 3rem;
  color: #a6a6a6;
  background-color: #262626;
  text-align: right;
}