@media only screen and (min-width: 1000px) {
    div.mobile {
        display: none !important;
        padding: 0 20px;
    }
}

@media only screen and (max-width: 999px) {
    div.desktop {
        display: none !important;
        padding: 0 20px;
    }
    
    .carousel-container {
        max-height: 550px !important;
    }

    .box {
        padding-top: 25px;
    }
}