#contact {
    margin: 0 5%;
}

.grey {
    color: #555
}

#form-start {
    margin-top: 4rem;
}