#special-offers {
    margin-left: 5%;
    margin-right: 5%;
    height: 120vh;
    text-align: justify;
}

a {
    color: #da2f10; 
    text-decoration: none;
    cursor: pointer;
}

@media only screen and (min-width: 1000px) and (max-height: 1000px) {
    #special-offers {
        height: 350vh;
  }
}

@media only screen and (min-width: 1000px) and (min-height: 1000px) {
    #special-offers {
        height: 175vh;
  }
}

@media only screen and (max-width: 999px) {
    #special-offers {
        height: 100%;
    }
}