#gallery {
  margin: 0 5% 5% 5%;
}

.gallery-div {
  background-color: #f5f1f0;
  margin-bottom: 2rem;
}

.gallery-div > div {
  padding: 15px;
}

.see-more-img {
  width: 100%;
  border: none;
  background: none;
  cursor: pointer;
}

.see-more-img > img {
  width: inherit;
}

.see-more-button {
    background: none!important;
    border: none;
    padding: 0!important;
    color: #da2f10;
    text-decoration: none;
    cursor: pointer;
  }
  
.popup-overlay {  background: rgba(0, 0, 0, 0.6);}
.popup-content {  position: absolute; background: rgb(255, 255, 255);  width: 375px; height: 700px; padding: 5px;}

@media only screen and (min-width: 1000px) {
  .gallery-div {
    display: flex;
  }

  .gallery-img {
    width: 60%;
  }
  
  .gallery-text {
    width: 40%;
    align-self: center;
  }

  #gallery .gallery-div:nth-child(2n+1) {
    flex-direction: row-reverse;
  }

  .popup-content {  position: absolute; background: rgb(255, 255, 255);  width: 450px; height: 735px; padding: 5px;}
}
