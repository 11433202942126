#about {
    margin: 0 5%;
    height: 120vh;
}
#about > p {
    line-height: 1.75rem;
}

@media only screen and (max-width: 999px) {
    #about {
        height: 100%;
    }
}