.form-input {
    margin-bottom: 2rem;
    padding: .75rem;
    width: 100%;
}

.submit-button {
    background-color: #1b1b1b;
    border: none;
    color: aliceblue;
    font-size: .9rem;
    margin-bottom: 4rem;
    padding: .75rem;
    text-align: center;
    text-decoration: none;
    display: block;
}